import axios from 'axios';
import { IOrderForEdit } from '../interfaces/order';
import { getOrdersByStatus, getOrderurl } from '../constants';

export const getOrders = (
  statuses: string,
  page: number,
  per_page: number,
  userId?: number,
  accord_order_state?: string,
  payment_status?: string,
  company_id?: string,
  date_from?: string,
  date_to?: string,
  hide_delivery_cost?: boolean,
  csv?: boolean,
  user_phone?: string | null,
  user_email?: string | null,
  user_name?: string | null,
  stock_status?: string,
  root?: boolean
) => {
  const boxberry = company_id?.toString() === '-2';
  return axios.get(
    `${getOrdersByStatus(statuses)}&page=${page}&per_page=${per_page}&root=${root}`,
    {
      params: {
        date_from,
        date_to,
        stock_status,
        payment_status,
        user_id: userId,
        email: user_email,
        phone: user_phone,
        fio: user_name,
        company_id: boxberry ? undefined : company_id,
        accord_order_state,
        hide_delivery_cost,
        csv,
        boxberry,
      },
    }
  );
};

export const deleteOrder = (id: number) => axios.delete(getOrderurl(id));

export const editOrder = (id: number, order: IOrderForEdit) =>
  axios.put(getOrderurl(id), order);

export const getOrderById = (id: number) => axios.get(getOrderurl(id));
